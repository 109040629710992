import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { isMobile } from 'react-device-detect'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import ThemeProvider, { GlobalStyle } from './Theme'
import LocalStorageContextProvider, { Updater as LocalStorageContextUpdater } from './contexts/LocalStorage'
import TokenDataContextProvider, { Updater as TokenDataContextUpdater } from './contexts/TokenData'
import GlobalDataContextProvider from './contexts/GlobalData'
import PairDataContextProvider, { Updater as PairDataContextUpdater } from './contexts/PairData'
import ApplicationContextProvider from './contexts/Application'
import UserContextProvider from './contexts/User'
import getLibrary from './utils/getLibrary'
import App from './App'
import Web3ReactManager from './components/Web3ReactManager/index'

import ApplicationUpdater from './state/application/updater'
import MulticallUpdater from './state/multicall/updater'
// import TransactionUpdater from './state/transactions/updater'

import store from './state'

const Web3ProviderNetwork = createWeb3ReactRoot('NETWORK')

// if ('ethereum' in window) {
//   ;(window.ethereum as any).autoRefreshOnNetworkChange = false
// }

// initialize GA
const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      storage: 'none',
      storeGac: false,
    },
  })
  ReactGA.set({
    anonymizeIp: true,
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

function ContextProviders({ children }) {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <LocalStorageContextProvider>
          <ApplicationContextProvider>
            <TokenDataContextProvider>
              <GlobalDataContextProvider>
                <PairDataContextProvider>
                  <UserContextProvider>{children}</UserContextProvider>
                </PairDataContextProvider>
              </GlobalDataContextProvider>
            </TokenDataContextProvider>
          </ApplicationContextProvider>
        </LocalStorageContextProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

function Updaters() {
  return (
    <>
      <LocalStorageContextUpdater />
      <PairDataContextUpdater />
      <TokenDataContextUpdater />
      <ApplicationUpdater />
      <MulticallUpdater />
    </>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <ContextProviders>
      <Updaters />
      <ThemeProvider>
        <Web3ReactManager>
          <React.Fragment>
            <GlobalStyle />
            <App />
          </React.Fragment>
        </Web3ReactManager>
      </ThemeProvider>
    </ContextProviders>
  </Provider>,
  document.getElementById('root')
)
