import { useMemo } from 'react'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useTokenContract } from './useContract'

export const useTotalSupply = (address) => {
  const tokenContract = useTokenContract(address)
  const data = useSingleCallResult(tokenContract, 'totalSupply')

  return useMemo(() => {
    return data ? Number(data.result?.[0]) : 0
  }, [data])
}
