import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
// import EthereumLogo from '../../assets/token-logo.png'
import defaultTokenList from '../../constants/token/default.json'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

// const StyledEthereumLogo = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   > img {
//     width: ${({ size }) => size};
//     height: ${({ size }) => size};
//   }
// `

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          ?
        </span>
      </Inline>
    )
  }

  // hard coded fixes for trust wallet api issues
  if (address?.toLowerCase() === '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb') {
    address = '0x42456d7084eacf4083f1140d3229471bba2949a8'
  }

  if (address?.toLowerCase() === '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f') {
    address = '0xc011a72400e58ecd99ee497cf89e3775d4bd732f'
  }

  // if (address?.toLowerCase() === '0x8148b71232162ea7a0b1c8bfe2b8f023934bfb58') {
  //   return (
  //     <StyledEthereumLogo size={size} {...rest}>
  //       <img
  //         src='https://raw.githubusercontent.com/restratagem/xenwave-public/main/_data/_tokenInfo/210/0x8148b71232162ea7a0b1c8bfe2b8f023934bfb58/logo.png'
  //         style={{
  //           boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
  //           borderRadius: '24px',
  //         }}
  //         alt=""
  //       />
  //     </StyledEthereumLogo>
  //   )
  // }
  /*
   const path = `https://raw.githubusercontent.com/restratagem/xenwave-public/main/_data/_tokenInfo${isAddress(
     address
   )}/logo.png`

  
  let defaultTokenList = 'https://raw.githubusercontent.com/restratagem/xenwave-public/main/_data/_tokenLists/default.json'

  fetch('https://raw.githubusercontent.com/restratagem/xenwave-public/main/_data/_tokenLists/default.json',{mode: 'no-cors'})
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {

      defaultTokenList = myJson
      console.log(defaultTokenList)
    });
    */

  const localPath = defaultTokenList.tokens.filter((a) => a.address === isAddress(address))

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={localPath[0] ? localPath[0].logoURI : ''}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
