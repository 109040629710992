import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = 'https://rpc.btnscan.com/'

export const NETWORK_CHAIN_ID = 210

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})
