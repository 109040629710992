import { Contract } from '@ethersproject/contracts'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import ERC20_ABI from '../constants/abis/ERC20.json'
import { useActiveWeb3React } from '../hooks'
import { useMemo } from 'react'
import { getContract } from '../utils/getContract'

function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(address: string | undefined) {
  return useContract(address, ERC20_ABI)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
