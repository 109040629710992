import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Title from '../Title'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
// import { transparentize } from 'polished'
// import { TYPE } from '../../Theme'
import { withRouter } from 'react-router-dom'
import {
  BarChart2,
  Search,
  Link2,
  // Layers,
  // Award
} from 'react-feather'
import Link from '../Link'
import { useSessionStart } from '../../contexts/Application'
// import { useDarkModeManager } from '../../contexts/LocalStorage'
// import Toggle from '../Toggle'

const Wrapper = styled.div`
  height: ${({ isMobile }) => (isMobile ? 'initial' : '99vh')};
  background: rgba(44, 44, 44, 0.97);
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(30px);
  color: ${({ theme }) => theme.text1};
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  margin: 0px 5px auto 5px;
  position: sticky;
  top: 0px;
  z-index: 9999;
  box-sizing: border-box;
  color: ${({ theme }) => theme.bg2};
  border-top: 0px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  :hover {
    opacity: 1;
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  margin-right: 0.75rem;
  font-size: 0.825rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  a {
    color: ${({ theme }) => theme.white};
  }
`

// const Polling = styled.div`
//   position: fixed;
//   display: flex;
//   left: 0;
//   bottom: 0;
//   padding: 1rem;
//   color: white;
//   opacity: 0.4;
//   transition: opacity 0.25s ease;
//   :hover {
//     opacity: 1;
//   }
// `
// const PollingDot = styled.div`
//   width: 8px;
//   height: 8px;
//   min-height: 8px;
//   min-width: 8px;
//   margin-right: 0.5rem;
//   margin-top: 3px;
//   border-radius: 50%;
//   background-color: ${({ theme }) => theme.green1};
// `

function SideNav({ history }) {
  const below1080 = useMedia('(max-width: 1080px)')

  const below1180 = useMedia('(max-width: 1180px)')

  const seconds = useSessionStart()

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper>
          <AutoColumn gap="1rem" style={{ marginLeft: '.75rem', marginTop: '1.5rem' }}>
            <Title />
            {!below1080 && (
              <AutoColumn gap="1.25rem" style={{ marginTop: '1rem' }}>
                <BasicLink to="/home">
                  <Option activeText={history.location.pathname === '/home' ?? undefined}>
                    <BarChart2 size={20} style={{ marginRight: '.75rem' }} />
                    Overview
                  </Option>
                </BasicLink>
                <BasicLink to="/tokens">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'tokens' ||
                        history.location.pathname.split('/')[1] === 'token') ??
                      undefined
                    }
                  >
                    <Search size={20} style={{ marginRight: '.75rem' }} />
                    Tokens
                  </Option>
                </BasicLink>
                <BasicLink to="/pairs">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'pairs' ||
                        history.location.pathname.split('/')[1] === 'pair') ??
                      undefined
                    }
                  >
                    <Link2 size={20} style={{ marginRight: '.75rem' }} />
                    Markets
                  </Option>
                </BasicLink>
              </AutoColumn>
            )}
          </AutoColumn>
          <AutoColumn gap="0.5rem" style={{ marginLeft: '.75rem', marginBottom: '4rem' }}>
            <HeaderText>
              <Link href="https://xenwave.com/" target="_blank">
                Xenwave ↗
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://github.com/restratagem" target="_blank">
                Github
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://discord.gg/n2ERW6HhWe" target="_blank">
                Discord
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://t.me/restratagem" target="_blank">
                Telegram
              </Link>
            </HeaderText>
            {!below1180 && (
              <p style={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left', fontWeight: '300', fontSize: '7pt' }}>
                <br />
                LAST UPDATED: {!!seconds ? seconds + ' SEC' : '-'}
              </p>
            )}
          </AutoColumn>
        </DesktopWrapper>
      ) : (
        <MobileWrapper>
          <Title />
        </MobileWrapper>
      )}
    </Wrapper>
  )
}
//             <Toggle isActive={isDark} toggle={toggleDarkMode} />
export default withRouter(SideNav)
