export const FACTORY_ADDRESS = '0xCba3Dc15Cfbcd900cF8133E39257c26727E86e3a'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://raw.githubusercontent.com/restratagem/xenwave-public/main/_data/_tokenLists/default.json',
]

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x3ab8ee631bed3cf6d77fe9f5dcda42bdca85c496', // BPEPE Unused ERC20 Contract
  '0x325d2cda5a5ce315cb7b647a2e084c8e30afec3d', // BPEPE Old BTS21 Wrong Contract
  '0xc9BDBA447C18651FeC1871c3dA25bF1eB4F1fa8e', // STRAT Old BTS21 Wrong Contract
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0xe5470c8aefbcd4bc609bfabfcf115b493a29970e', // BPEPE-WBTN Old BTS21 Wrong Contract
  '0x0fc303515af5214eb5b514d4c073ff48c5be3109', // BPEPE-WBTN Unused ERC20 Contract
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or BTN pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = ['']

export enum ChainId {
  NOVA = 210,
}

export const CALL_STAKING_ADDRESS = '0x1eEAF2AC0fA5D608CC803014DB9A943a80Eaa8eB'

export const BOND_STAKING_ADDRESS = '0xaaBaB0FB0840DFfFc93dbeed364FB46b1ffD92EE'

export const LIQUID_STAKING_ADDRESS = '0x2A3605d98e26Ee6f682084d8E8018f71d867dcB3'

export const INCOME_STAKING_ADDRESS = '0x1bF49Db5Cb35575483dB2E630510fac8d8F177b9'

export const FARM_ADDRESS = '0x00501Ed66d67B1127809E54395F064e256b75B23'
