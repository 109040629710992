import { useState, useCallback, useEffect, useRef, useMemo } from 'react'

import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
// import { shade } from 'polished'
// import Vibrant from 'node-vibrant'
// import { hex } from 'wcag-contrast'
// import { isAddress } from '../utils'
import copy from 'copy-to-clipboard'
import { ChainId } from '../constants'
import { useMulticallContract } from './useContract'
import { isAddress } from '../utils/getContract'
import { useSingleContractMultipleData } from '../state/multicall/hooks'
import { ethers } from 'ethers'
import { BTN_USDT_PAIR } from '../apollo/queries'
import { client } from '../apollo/client'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & {
  chainId?: ChainId
} {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>('NETWORK')
  return context.active ? context : contextNetwork
}

export function useColor(tokenAddress, token) {
  const [color] = useState('#E5274E')
  /*
  if (tokenAddress) {
    const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
      tokenAddress
    )}/logo.png`
    if (path) {
      Vibrant.from(path).getPalette((err, palette) => {
        if (palette && palette.Vibrant) {
          let detectedHex = palette.Vibrant.hex
          let AAscore = hex(detectedHex, '#FFF')
          while (AAscore < 3) {
            detectedHex = shade(0.005, detectedHex)
            AAscore = hex(detectedHex, '#FFF')
          }
          if (token === 'DAI') {
            setColor('#FAAB14')
          } else {
            setColor(detectedHex)
          }
        }
      })
    }
  } */
  return color
}

export function useCopyClipboard(timeout = 500) {
  const [isCopied, setIsCopied] = useState(false)

  const staticCopy = useCallback((text) => {
    const didCopy = copy(text)
    setIsCopied(didCopy)
  }, [])

  useEffect(() => {
    if (isCopied) {
      const hide = setTimeout(() => {
        setIsCopied(false)
      }, timeout)

      return () => {
        clearTimeout(hide)
      }
    }
  }, [isCopied, setIsCopied, timeout])

  return [isCopied, staticCopy]
}

export const useOutsideClick = (ref, ref2, callback) => {
  const handleClick = (e) => {
    if (ref.current && ref.current && !ref2.current) {
      callback(true)
    } else if (ref.current && !ref.current.contains(e.target) && ref2.current && !ref2.current.contains(e.target)) {
      callback(true)
    } else {
      callback(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export default function useInterval(callback: () => void, delay: null | number) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const current = savedCallback.current
      current && current()
    }

    if (delay !== null) {
      tick()
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}

export function useETHBalances(uncheckedAddresses?: (string | undefined)[]): { [address: string]: any } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map((address) => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: any }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = ethers.utils.formatEther(value)
        return memo
      }, {}),
    [addresses, results]
  )
}

export const useBTNPrice = () => {
  const [price, setPrice] = useState(0)

  useInterval(async () => {
    let data: any = await client.query({
      query: BTN_USDT_PAIR,
    })
    console.log(data)
    if (data?.data?.pair?.token0Price) {
      setPrice(Number(data?.data?.pair?.token0Price))
    }
  }, 2000)
  console.log(price)
  return price
}
